import { Container } from './styles';

function UserList() {
  return (
    <Container>
      <h1>Users List</h1>
    </Container>
  );
}

export default UserList;
