import React from 'react';

import { Container } from './styles';

function Profile() {
  return (
    <Container>
      <h1>Profile</h1>
    </Container>
  );
}

export default Profile;
