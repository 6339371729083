import { Container } from './styles';

function NotFound() {
  return (
    <Container>
      <h1>Page not found</h1>
    </Container>
  );
}

export default NotFound;
